import * as React from 'react'
import Layout from "../components/layout";

import {HeadFC, Link} from "gatsby";

interface College {
    name: string
    name_eng: string
    intro: string
    href: string
    img_url: string
}

import ArtsSocialScienceCelticStudiesImg from "../img/college/arts_social_science_and_celtic_studies.webp"
import ScienceEngineeringImg from "../img/college/science_and_engineering.webp"
import BusinessPublicPolicyLawImg from "../img/college/business_public_policy_and_law.webp"
import MedicineNursingHealthScienceImg from "../img/college/medicine_nursing_and_health_science.webp"
import {Content} from "../components/content";
import Consumer from "../context";
import {SEO} from "../components/seo";

let college_list: College [] = [
    {
        name: "艺术、社科与凯尔特研究学院",
        name_eng: "College of Arts, Social Sciences, and Celtic Studies",
        intro: '',
        href: '/college/arts-social-sciences-and-celtic-studies',
        img_url: ArtsSocialScienceCelticStudiesImg
    },
    {
        name: "医学、护理与健康科学学院",
        name_eng: "College of Medicine, Nursing and Health Sciences",
        intro: '',
        href: '/college/medicine-nursing-and-health-science',
        img_url: MedicineNursingHealthScienceImg
    },
    {
        name: "商学、公共政策与法学院",
        name_eng: "College of Business, Public Policy and Law",
        intro: '',
        href: '/college/business-public-policy-and-law',
        img_url: BusinessPublicPolicyLawImg
    },
    {
        name: "科学与工程学院",
        name_eng: "College of Science and Engineering",
        intro: '',
        href: '/college/science-and-engineering',
        img_url: ScienceEngineeringImg
    },
];

const CollegesPage = () => {
    return (
        <Layout>

            <Consumer>
                {({ data, set }) => (
                    (data.category === 'college') ? console.log(data.category) :  set({ category: 'college' })
                )}
            </Consumer>

            <div className="relative flex flex-col justify-center overflow-hidden py-8">
                <div className="relative w-full px-6 py-12 md:mx-auto md:max-w-3xl lg:max-w-7xl lg:pt-16 lg:pb-28">
                    <div className="container px-5 mx-auto">
                        <Content>
                            <h1 className="text-galway-maroon">学院设置</h1>
                            <hr />
                            <blockquote>高威大学的本科和硕士课程覆盖面广，选择丰富，学习方式灵活。高威大学有4个总学院，19个院系和60多门学科。不论你感兴趣的是什么，一定能在这里找到适合你的课程！</blockquote>
                        </Content>
                        <div className="flex flex-wrap mt-12">
                            {
                                college_list.map((item) => (
                                    <div className="md:w-1/2 w-full p-4">
                                        <div className="bg-gray-100 p-6 rounded-lg h-full">
                                            <img className="h-40 rounded w-full object-cover object-center mb-6"
                                                 src={item.img_url} alt="content" />
                                            {/*@ts-ignore*/}
                                            <Link to={item.href}>
                                                <h3 className="tracking-widest text-galway-maroon text-sm font-medium">{item.name_eng}</h3>
                                                <h2 className="text-xl font-medium title-font mb-4">{item.name}</h2>
                                                <p className="leading-relaxed text-base">{item.intro}</p>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default CollegesPage

export const Head = () => (
    <SEO title="学院介绍" description="高威大学在2023年QS世界大学排名中位列爱尔兰第3，世界第270名。学院设置包括理科、医学、工程、信息技术、人文学科和社会科学。高威大学拥有很多世界级的研究中心，如国家生物医学工程科学中心（NCBES）、爱尔兰高端计算中心（ICHEC）、数字企业研究所（DERI）。其中NCBES汇集了生物学、化学、医学、工程学等多领域的世界一流研究人员，并提供世界先进的研究设备，是爱尔兰国际级的生物医学研究机构。" />
)