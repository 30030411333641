import React from "react"

export const Content = ({ children }: React.PropsWithChildren<{}>) => {
    return (
        <>
            <section className="prose prose-slate mx-auto max-w-7xl">
                { children }
            </section>
        </>
    )
}